
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import "../../../public/fonts/BalsamiqSansRegular/styles.css";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body, .container-fluid {
  min-height: 100vh;
  width: 100vw;
  max-width:100%;
  font-family: BalsamiqSansRegular, 'monospace';
}

#logo {
  width: 70vw;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  //margin: 20vh;
  margin: 0 10px;
}

.container-fluid {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#phrasebox {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  min-height: 300px;
}
